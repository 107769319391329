import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logord from "../images/FIEMA_R&D.png"

const Donate = () => (
  <Layout>
    <SEO title="Donate" />
    <div className="container">
      <h1>Donate</h1>
      <p>
        Thank you for your interest to financially support the work of Indian Evangelical Mission (IEM) from here in Australia.
        We are grateful for your partnership in the Gospel and showing Christ&rsquo;s love to the needy in India.
        Support for IEM in India is being sent from Australia through the Friends of IEM Australia (FIEMA) team.
        FIEMA operates under two companies registered under the <em>Australian Charities and Not-for-profits Commission Act 2012</em></p>
      <ul>
        <li>Friends of IEM Australia Ltd</li>
        <li>Friends of IEM Australia Relief and Development Ltd</li>
      </ul>
      <h2>Friends of IEM Australia Ltd (FIEMA)</h2>
      <p>
        FIEMA is a not-for-profit charity with an objective to advance Christian faith particularly focusing on&ndash;
      </p>
      <ul>
        <li>Motivating and challenging Christians for world outreach and Christian care</li>
        <li>Promoting the mission and ministries of Indian Evangelical Mission India</li>
      </ul>
      <p><strong>Missional projects</strong> we are actively working with IEM on:</p>
      <ul>
        <li>Motorbikes for Missionaries &ndash; To speed up the ministry in pioneering areas.</li> 
        <li>Training Mission Workers &ndash; To equip future native Gospel workers and enabling the work of IEM&rsquo;s missionary training institute OTI.</li>
        <li>Missionary health fund &ndash; To care for the health and wellbeing of our missionaries and their children.</li>
        <li>Missionary children&rsquo;s education fund &ndash; To educate the missionary kids by overcoming barriers like remoteness of location or cost of tuition fees.
        </li>
      </ul>
      <p><em>Note: Donations to above projects are NOT Tax Deductible, but are essential to help IEM reach its vision for 2025</em></p>
      <h4>To support FIEMA projects by direct bank transfers</h4>
      <p>Please use the CBA bank account details below:</p>
      <p>Account Name: Friends of IEM Australia Ltd</p>
      <p>BSB: 064 162</p>
      <p>Account Number: 117 318 22</p>
      <p>Reference: Please enter your surname along with the state (QLD, NSW, VIC, etc) you live in</p>
     <p>
        <em>
            When the fund transfer is complete, please send&nbsp;an email to&nbsp;
            <a href="mailto:treasurer@fiema.org">treasurer@fiema.org</a>&nbsp;
            calling out which project you would like to earmark your donation for receipting purposes. 
            Please note, we may redirect your funds to another project if the selected project is oversubscribed. 
        </em>
     </p>
      <h4>To support FIEMA projects using Credit Card or Debit card</h4> 
      <div style={{ padding: '20px 0' }}>
        <div style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '259px',
          background: '#FFFFFF',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '-2px 10px 5px rgba(0, 0, 0, 0)',
          borderRadius: '10px',
          fontFamily: 'SQ Market, Helvetica, Arial, sans-serif',
        }}>
          <img
            src="https://items-images-production.s3.us-west-2.amazonaws.com/files/5d72d3618f5d07fccfb5e21b13f4e8a0bc2e9a3c/original.png"
            alt="FIEMA Donation button"
            style={{ width: '100%' }}
          />
          <div style={{ padding: '20px' }}>
            < a target="_blank"
              data-url="https://fiema.square.site/s/shop"
              href="https://fiema.square.site/s/shop"
              style={{
                display: 'inline-block',
                fontSize: '18px',
                lineHeight: '48px',
                height: '48px',
                color: '#ffffff',
                minWidth: '212px',
                backgroundColor: '#cc0023',
                textAlign: 'center',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1) inset',
                borderRadius: '6px'
              }}>Donate</a>
          </div>
        </div>
      </div>

      <h2>FIEMA Relief &amp; Development Ltd (FIEMA R&amp;D)</h2>
      <p>FIEMA R&amp;D is a not-for-profit charity with an objective to provide public benevolent relief in India.</p>
      <p><strong>Benevolent projects</strong> we are actively working with IEM on:</p>
      <ul>
        <li>Tailoring Centres &ndash; &lsquo;Stitch in time&rsquo; community self-empowerment program for women in rural India.</li>
        <li>Hostels and Day Care Centres &ndash; To support the needs of the &lsquo;Jeevan Jyothi Nivas&rsquo; Girls &amp; Boys hostels and IEM day care centres across India.</li>
        <li>Health Awareness Programs &ndash; To equip our native workers and missionaries to provide basic healthcare and disease prevention trainings and events.</li>
        <li>Literacy Programs &ndash; To enable the rural and tribal communities to read and write in their heart language</li>
      </ul>
      <p><em>Note: Donations above $2 are Tax Deductible. Please contact us to receive receipts for your tax records.</em></p>
      <h4>To support FIEMA R&amp;D projects by direct bank transfers</h4>
      <p>Please use the CBA bank account details below:</p>
      <p>Account Name: FIEMA Relief and Development Ltd</p>
      <p>BSB: 064 162</p>
      <p>Account Number: 117 319 02</p>
      <p>Reference: Please enter your surname along with the state (QLD, NSW, VIC, etc) you live in</p>
      <p>
        <em>
            When the fund transfer is complete, please send&nbsp;an email to&nbsp;
            <a href="mailto:treasurer@fiema.org">treasurer@fiema.org</a>&nbsp;
            calling out which project you would like to earmark your donation for receipting purposes. 
            Please note, we may redirect your funds to another project if the selected project is oversubscribed. 
        </em>
     </p>
      <h4>To support FIEMA R&amp;D projects using Credit Card or Debit card</h4>
      <div style={{ padding: '20px 0' }}>
        <div style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '259px',
          background: '#FFFFFF',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '-2px 10px 5px rgba(0, 0, 0, 0)',
          borderRadius: '10px',
          fontFamily: 'SQ Market, Helvetica, Arial, sans-serif',
        }}>
          <img
            src={logord}
            alt="FIEMA R&D Donation button"
            style={{ width: '100%' }}
          />
          <div style={{ padding: '20px' }}>
            < a target="_blank"
              data-url="https://www.paypal.com/donate/?hosted_button_id=58A3TG97QAPZ4"
              href="https://www.paypal.com/donate/?hosted_button_id=58A3TG97QAPZ4"
              style={{
                display: 'inline-block',
                fontSize: '18px',
                lineHeight: '48px',
                height: '48px',
                color: '#ffffff',
                minWidth: '212px',
                backgroundColor: '#cc0023',
                textAlign: 'center',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1) inset',
                borderRadius: '6px'
              }}>Donate</a>
          </div>
        </div>
      </div>
      <div id="donate-button-container">
        <div id="donate-button"></div>
      </div>
    </div>
  </Layout >
)

export default Donate
